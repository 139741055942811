<template>
	<div class="cw-app-wrapper">
		<div class="cw-app-module">
			<MStepper />
		</div>
		<div class="cw-app-container">
			<div class="pre-subs-box01">
				<h3>전자계약서 발송</h3>
				<p class="mb-4" v-if="custSelf">
					주문 고객님에게 주문 확인 및 계약서 서명을 위한
					<br />알림을 발송 합니다. 계약서와 주문내역을 확인 후 계약서 발송을
					눌러주세요.
				</p>
				<p class="mb-4" v-else>
					주문 고객님에게 전자계약 동의를 위한 인증번호를 발송합니다.
					<br />
					아래 주문 내용을 확인 후 인증번호 발송을 눌러주세요.
				</p>
				<v-btn
					large
					default
					class="btn_secondary"
					:disabled="false"
					@click="signature()"
					v-if="custSelf"
				>
					<!-- v-show="['development'].includes(isDebugLabel)" -->
					계약서 미리보기
				</v-btn>
			</div>

			<div class="pre-subs-box03">
				<h4>주문내역</h4>
				<em>
					총<i>{{ orderList.length }}</i
					>개</em
				>
			</div>

			<div class="pre-subs-box05">
				<v-expansion-panels focusable v-model="panel" multiple>
					<v-expansion-panel v-for="(ord, i) in orderList" :key="i">
						<v-expansion-panel-header>
							<h5>
								주문<em>{{ i + 1 }}</em
								>.<i
									:class="{
										'ft-orange': canceledOrderChk(i) === false
									}"
									v-if="ord.CONTRACT_ID == '1'"
									>{{ ord.GOODS_NM ? ord.GOODS_NM : deliveryInfos[i].prodNm }} /
									{{ ord.E_SALE_GUBUN }}</i
								>
								<i
									:class="{
										'ft-orange': canceledOrderChk(i) === false
									}"
									v-else
									>{{ ord.GOODS_NM ? ord.GOODS_NM : deliveryInfos[i].prodNm }} /
									{{ ord.E_SALE_GUBUN }}({{
										ord.DUTY_USE_MONTH
											? ord.DUTY_USE_MONTH
											: getUseDutyMonth(ord)
									}})</i
								>
							</h5>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<dl>
								<dt>주문번호</dt>
								<dd>{{ ord.ORDER_NO }}</dd>
							</dl>
							<dl>
								<dt>구매방식</dt>
								<dd>{{ ord.E_SALE_GUBUN }}</dd>
							</dl>
							<dl v-if="ord.GOODS_AMT > 0 && canceledOrderChk(i)">
								<dt>수납정보</dt>
								<dd>
									{{ ord.GOODS_AMT | comma }} 원 <br />{{
										ord.T_A0401_PAYMENT_LIST.filter(x => x.GUBUN === '2')[0]
											? ord.T_A0401_PAYMENT_LIST.filter(x => x.GUBUN === '2')[0]
													.CARDCOM_NM
											: ''
									}}
									{{
										getMaskedAccNo(
											ord.T_A0401_PAYMENT_LIST.filter(x => x.GUBUN === '2')[0]
												? ord.T_A0401_PAYMENT_LIST.filter(
														x => x.GUBUN === '2'
												  )[0].CARD_NO
												: ''
										)
									}}
								</dd>
							</dl>

							<dl v-if="ord.MONTH_AMT > 0 && canceledOrderChk(i)">
								<dt>정기결제</dt>
								<dd>
									{{ ord.MONTH_AMT | comma }} 원/월 <br />{{
										ord.CARDCOM_NM ? ord.CARDCOM_NM : ''
									}}
									{{ ord.CARD_NO ? getMaskedAccNo(ord.CARD_NO) : '' }}
								</dd>
							</dl>
							<dl v-if="canceledOrderChk(i)">
								<dt>설치주소</dt>
								<dd>{{ ord.CITY1 }} {{ ord.STREET }}</dd>
							</dl>
							<dl v-if="canceledOrderChk(i)">
								<dt>설치일시</dt>
								<dd v-if="isPreContract" class="ft-blue">
									{{
										deliveryInfos[i].workerType !== 'EXPRESS'
											? ord.E_YYYY_MM + ' 배정 진행 가능'
											: '택배 배송'
									}}
								</dd>
								<dd v-else>
									{{
										deliveryInfos[i].workerType !== 'EXPRESS'
											? dateToBookingText({
													bookingDate: deliveryInfos[i].bookingDate,
													bookingSlot: deliveryInfos[i].bookingSlot,
													bookingSlotTime: deliveryInfos[i].bookingSlotTime
											  })
											: '택배 배송'
									}}
								</dd>
							</dl>
							<dl v-if="canceledOrderChk(i) === false">
								<dt>주문상태</dt>
								<dd>주문삭제</dd>
							</dl>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</div>
		</div>
		<div class="cw-app-fixed">
			<div class="u-btns-group">
				<div class="column">
					<v-btn
						large
						default
						class="u-btn-active"
						:disabled="false"
						@click="send()"
						v-if="custSelf"
					>
						계약서 발송
					</v-btn>
					<v-btn color="primary" class="btn_col2 col-12" @click="send()" v-else>
						인증번호 발송
					</v-btn>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	fetchEContract,
	sendAuthNo,
	sendAlimTalk,
	fetchCreateEcCookie
} from '@/apis/order.ec.api'
import { paymentInfo } from '@/apis/order.recp.api'
import { dateToBookingText } from '@/utils/schedule.js'
import { fetchCustSetup } from '@/apis/order.verify.api'
import {
	checkingStatus,
	fetchWebOrderInfoById,
	mOrderInfoByEtc,
	postWebOrderInfoById
} from '@/apis/order.status.api'
import filters from '@/mixins/filters'
import { registerCallbackToApp, closePopView } from '@/utils/navigation'
import {
	getPhone,
	getMaskedAccNo,
	getPrdName,
	getSellType,
	getMessageByStatusCode
} from '@/utils/ec'
import { notEqualIgnoreMasking, isParameterEmpty } from '@/utils/common'
export default {
	mixins: [filters],
	props: {},
	data() {
		return {
			custSelf: false,
			name: '',
			phone: '',
			orderNo: '',
			validDate: '',
			panel: [],
			orderList: [],
			deliveryInfos: [],
			webOrderSum: {},
			mobOrderNo: this.$store.getters['verify/mobOrderNo'],
			orderStatusList: [],
			isPreContract: this.$store.state['verify'].customer.orderType === 'C'
		}
	},
	created() {
		this.initAppPage()
	},
	computed: {
		isDebugLabel() {
			return process.env.NODE_ENV
		}
	},
	async mounted() {
		if (this.isParameterEmpty(this.mobOrderNo)) return

		this.custSelf = this.$store.getters['verify/custSelf']
		this.phone = this.$store.getters['verify/custPhone']
		this.deliveryInfos = this.$store.getters['schedule/deliveryInfos']

		// 주문 이어서 하기시 store 값이 존재할 경우 sessionStorage에 store 저장
		if (this.custSelf) {
			sessionStorage.removeItem('store')
			sessionStorage.setItem('store', JSON.stringify(this.$store.state))
			this.$log(`주문번호: ${this.$store.getters['verify/mobOrderNo']}`)
		} else {
			// 계약서 미리보기 후 재진입시 sessionStorage 내 store 값 가져옴
			const storageStore = sessionStorage.getItem('store')
			this.$store.replaceState(JSON.parse(storageStore))
			this.custSelf = this.$store.getters['verify/custSelf']
		}

		let customerType = (this.name = this.$store.getters['verify/customerType'])
		if (customerType === 'L') {
			this.name = this.$store.getters['verify/bizName']
		} else {
			this.name = this.$store.getters['verify/custName']
		}

		// 계약확인 조회
		const res = await fetchEContract({
			mobOrderNo: this.mobOrderNo
		})
		this.orderList = res.resultObject.data[0].setup

		// [BEGIN] deliveryInfos PRODH9 저장
		// PRODH9 값은 MB2 계약확인 RFC를 통해 가져오므로
		// 최초 진입 시 deliveryInfos에 저장
		let deliveryInfosUpdateCheck = false
		this.deliveryInfos.forEach(v => {
			if (!v.PRODH9) {
				v.PRODH9 = this.orderList.filter(
					x => x.ORDER_NO === v.orderNo
				)[0].PRODH9
				deliveryInfosUpdateCheck = true
			}
		})

		if (deliveryInfosUpdateCheck) {
			await this.$store.dispatch(
				'schedule/setDeliveryInfos',
				this.deliveryInfos
			)

			const info = await fetchWebOrderInfoById(this.mobOrderNo)
			const webParams = res.resultObject
			webParams.step = '06' // 전자계약
			webParams.statusCode = '108' // 서명대기
			webParams.orderInfoJson = JSON.stringify(
				Object.assign(JSON.parse(info.resultObject.orderInfoJson), {
					deliveryInfos: this.deliveryInfos
				})
			)
			await postWebOrderInfoById(this.mobOrderNo, webParams)
		}
		// [END] deliveryInfos PRODH9 저장

		// 알림톡 발송용 주문번호 셋팅
		let ordNoStr = ''
		this.orderList.forEach((res, i) => {
			if (i === 0) {
				ordNoStr = `${res.ORDER_NO}`
			} else {
				ordNoStr = `${ordNoStr}, ${res.ORDER_NO}`
			}
		})
		this.orderNo = ordNoStr
		this.panel = [...Array(this.orderList.length).keys()].map((k, i) => i)

		//주문상태조회
		await this.createOrderStatusList()

		// 고객입력 정보와 SAP정보 비교
		await this.checkCustInfo()
	},
	methods: {
		getSellType,
		getPrdName,
		getPhone,
		getMaskedAccNo,
		dateToBookingText,
		isParameterEmpty,
		alertMessage() {
			this.$alert({
				message:
					'고객이 직접 등록한 정보(결제수단 및 설치처)와 일치하지 않은 내용이 있습니다. 원활한 계약 진행을 위해 고객에게 확인 후 진행해 주세요.'
			})
		},
		goBackPage() {
			this.$router.go(-1)
		},
		signature() {
			const mobOrderNo = this.$store.getters['verify/mobOrderNo'] // 웹주문번호

			fetchCreateEcCookie({
				webOrderNo: mobOrderNo,
				phoneNumber: this.phone
			})
				.then(res => {
					const { eMobOrderNo } = res.resultObject
					const url = `${process.env.VUE_APP_NEW_SIGN_URL}/smarteform/reqMain/requestRentalView.jcg?zsndNo=${eMobOrderNo}&sysId=M&dmId=${process.env.VUE_APP_DMID}`
					window.open(url)
				})
				.catch(() => {
					this.$alert({
						message:
							'일시적인 통신 장애로 서비스에 접속할 수 없습니다. 문제가 계속된다면, 담당자에게 문의해 주세요.'
					})
				})
		},
		canceledOrderChk(i) {
			if (
				this.orderList.length === this.orderStatusList.length &&
				this.orderStatusList[i].ORDER_STATUS === '203'
			) {
				return false
			} else {
				return true
			}
		},
		getUseDutyMonth(ord) {
			let month = ''
			this.deliveryInfos.forEach(v => {
				if (v.orderNo === ord.ORDER_NO) {
					month = v.rentalYears
				}
			})
			return month + '개월'
		},

		debugUrl(userKey) {
			this.$log(
				'url with userKey:',
				`${process.env.VUE_APP_FRT_URL}/order/ec/cust/pass?userKey=${userKey}`
			)
		},
		async createOrderStatusList() {
			const orderNoList = []
			for (const order of this.orderList) {
				orderNoList.push(order.ORDER_NO)
			}

			const response = await checkingStatus({
				saleCd: this.$store.getters['common/codyNo'],
				kunnr: this.$store.getters['verify/custNo'],
				orderNos: orderNoList
			})

			this.orderStatusList = response.resultObject.T_ORDER_STATUS
		},
		async allCanceledOrderChkAndUpdate() {
			const cancelYn = this.orderStatusList.every(v => v.ORDER_STATUS === '203')
			this.$log('all order status cancel : ', cancelYn)
			if (cancelYn) {
				const res = await fetchWebOrderInfoById(this.mobOrderNo)
				const webParams = res.resultObject
				webParams.statusCode = '203'

				await postWebOrderInfoById(this.mobOrderNo, webParams)

				const msg = getMessageByStatusCode('203')
				await this.$alert({
					message: msg
				})
			}

			return cancelYn
		},
		async sendSelf() {
			this.$log('sendSelf')
			let alertRes = false

			alertRes = await this.$alert({
				message: `${this.getPhone(
					this.phone
				)}으로 고객 서명을 위한 알림을 발송했습니다.`
			})

			if (alertRes) {
				let prdNm = this.orderList[0] ? this.orderList[0].GOODS_NM : '제품명'
				let count =
					this.orderList[0] && this.orderList[0].length > 0
						? this.orderList[0].length - 1
						: ''
				const atParams = {
					reqCd: '1', // 1 : 계약서발송, 2 : 주문완료, 3 : 서명완료재진입
					gubun: this.custSelf ? '1' : '2', // 1 : 고객직접, 2 : 코디대행
					name: this.name,
					phone: this.phone,
					orderNo: this.orderNo, // TODO orderList에서 추출
					url: `${process.env.VUE_APP_FRT_URL}/order/ec/cust/pass`,
					orderInfoId: this.$store.getters['verify/mobOrderNo'], // 웹주문번호
					customerNo: this.$store.getters['verify/custNo'],
					codyNo: this.$store.getters['common/codyNo'],
					codyPhoneNo: this.$store.getters['common/codyPhoneNo'],
					goodsNm: prdNm,
					goodsInfo: count !== '' ? `${prdNm} 외 ${count}대` : `${prdNm}`,
					onceAmt: this.$options.filters.comma(
						this.webOrderSum.totalSumOfRecpAmt
					),
					monthAmt: this.$options.filters.comma(
						this.webOrderSum.totalSumOfMonthAmt
					)
				}

				const atRes = await sendAlimTalk(atParams)
				if (atRes.resultObject.userKey) {
					// 디버깅용 로그출력
					this.debugUrl(atRes.resultObject.userKey)
					this.$store.dispatch('ec/setUserKey', atRes.resultObject.userKey)
					// 모바일 주문 진행현황
					this.$router.push({ name: 'order-status' })
				} else {
					this.$log(JSON.stringify(atRes))
				}
			}
		},
		async sendCody() {
			this.$log('sendCody')
			let params = {
				name: this.name,
				phoneNumber: this.phone
			}

			const res = await sendAuthNo(params)
			this.$log('res:', JSON.stringify(res))
			this.$store.dispatch('ec/setUserKey', res.resultObject.userKey)

			let alertRes = false

			alertRes = await this.$alert({
				message: `${this.getPhone(
					this.phone
				)}으로 [전자계약 동의를 위한 인증번호 전달 안내] 문자메시지를 발송했습니다.<br /><br />인증번호를 전달받아 계약을 완료해주세요.`
			})

			if (alertRes) {
				let prdNm = this.orderList[0] ? this.orderList[0].GOODS_NM : '제품명'
				let count =
					this.orderList[0] && this.orderList[0].length > 0
						? this.orderList[0].length - 1
						: ''
				let atParams = {
					reqCd: '1', // 1 : 계약서발송, 2 : 주문완료, 3 : 서명완료재진입
					gubun: this.custSelf ? '1' : '2', // 1 : 고객직접, 2 : 코디대행
					name: this.name,
					phone: this.phone,
					orderNo: this.orderNo, // TODO orderList에서 추출
					goodsNm: prdNm,
					goodsInfo: count !== '' ? `${prdNm} 외 ${count}대` : `${prdNm}`,
					url: `${process.env.VUE_APP_FRT_URL}/order/ec/cust/pass`,
					orderInfoId: this.$store.getters['verify/mobOrderNo'], // 웹주문번호
					customerNo: this.$store.getters['verify/custNo'],
					codyNo: this.$store.getters['common/codyNo'],
					codyPhoneNo: this.$store.getters['common/codyPhoneNo'],
					onceAmt: this.$options.filters.comma(
						this.webOrderSum.totalSumOfRecpAmt
					),
					monthAmt: this.$options.filters.comma(
						this.webOrderSum.totalSumOfMonthAmt
					)
				}

				const atRes = await sendAlimTalk(atParams)
				if (atRes.resultObject.userKey) {
					// 디버깅용 로그출력
					this.debugUrl(atRes.resultObject.userKey)
					// 전자계약서 확인
					this.$router.push({ name: 'ec-auth' })
				} else {
					this.$log(JSON.stringify(atRes))
				}
			}
		},
		async send() {
			if (this.isParameterEmpty(this.mobOrderNo)) return
			await this.createOrderStatusList()
			const allCanceledOrder = await this.allCanceledOrderChkAndUpdate()
			if (allCanceledOrder) {
				this.$router.push({ name: 'order-status' })
				return
			}

			if (this.custSelf) {
				let arrParam = {
					id: this.$store.getters['verify/mobOrderNo'], // 웹주문번호
					statusCode: '108'
				}
				const res = await mOrderInfoByEtc(arrParam)
				let msg = undefined
				if (res.resultObject.statusCode !== '108') {
					msg = getMessageByStatusCode(res.resultObject.statusCode)
				}
				if (msg !== undefined) {
					await this.$alert({
						message: msg
					})
					sessionStorage.removeItem('customer')
					this.$router
						.push({
							name: 'order-status',
							query: {
								user_no: sessionStorage.getItem('codyNoEnc'),
								dept_cd: this.$store.getters['common/deptCd'],
								type: this.$store.getters['common/type']
							}
						})
						.catch()
				} else {
					await this.sendSelf()
				}
			} else {
				await this.sendCody()
			}
		},
		async initAppPage() {
			registerCallbackToApp(
				{
					cbPhysicalBack: 'cbPhysicalBack',
					isNotUseDsHeaderPhysicalBack: true
				},
				this
			)
		},
		async cbPhysicalBack() {
			const ok = await this.$alert({
				message: '홈화면으로 이동하시겠습니까?',
				okText: '확인',
				cancelText: '취소'
			})
			if (ok) closePopView()
		},
		async checkCustInfo() {
			// 결제정보 체크
			// PAY_TYPE (R: 정기결제, O: 수납), CONTRACT_ID (1: 일시불, 2:렌탈)
			const info = await fetchWebOrderInfoById(this.mobOrderNo)
			const sum = JSON.parse(info.resultObject.orderInfoJson)
			this.webOrderSum = sum.PAY_SUMMARY_AMT

			const payInfo = (
				await paymentInfo(this.mobOrderNo, { zwebRecp: '04' })
			).resultObject.data.PAYMENT_LIST.filter(x => x.MAIN_PAYMENT)

			if (payInfo.length > 0) {
				// 수납결제
				const recpInfo = sum.recp.recpParams
				if (sum.PAY_SUMMARY_AMT.totalSumOfRecpAmt > 0 && recpInfo) {
					const pay = payInfo.filter(
						pay => pay.PAY_TYPE === 'O' && pay.AF_CARD_NO.length > 0
					)[0]

					// AF_CARD_NO 값이 없는 경우 비교 X
					if (pay && recpInfo) {
						if (
							notEqualIgnoreMasking(
								pay.AF_CARD_NO,
								recpInfo.cardn ?? recpInfo.suacc
							)
						) {
							this.$log('수납결제 불일치')
							this.alertMessage()
							return
						}
					}
				}

				// 정기결제 (일시불인 경우 비교 X)
				if (sum.PAY_SUMMARY_AMT.totalSumOfMonthAmt > 0) {
					const pay = payInfo.filter(
						pay => pay.PAY_TYPE === 'R' && pay.AF_CARD_NO.length > 0
					)[0]
					const orderList = this.orderList.filter(
						x => x.CONTRACT_ID === '2' && x.CARD_NO.length > 0
					)

					if (
						orderList.findIndex(x =>
							notEqualIgnoreMasking(pay.AF_CARD_NO, x.CARD_NO)
						) > -1
					) {
						this.$log('정기결제 불일치')
						this.alertMessage()
						return
					}
				}
			}

			// 설치처정보 체크
			const setupInfo = (await fetchCustSetup(this.mobOrderNo)).resultObject
				.data.setup
			if (setupInfo) {
				if (
					this.orderList.findIndex(x =>
						notEqualIgnoreMasking(setupInfo.telNumber, x.TEL_NUMBER)
					) > -1
				) {
					this.$log('휴대폰번호 불일치')
					this.alertMessage()
					return
				}

				if (
					this.orderList.filter(
						x =>
							x.CITY1.trim() === setupInfo.addr1.trim() &&
							x.STREET.trim() === setupInfo.addr2.trim()
					).length !== this.orderList.length
				) {
					this.$log('주소 불일치')
					this.alertMessage()
					return
				}
			}
		}
	}
}
</script>

<style scoped lang="scss"></style>
